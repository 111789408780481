import { useEffect, useRef, useState } from "react";
import apiSocket from "../../services/socket";

function AudioPlayer({ isVoiceActive }) {
  const [audios, setAudios] = useState([]);
  const [audioPlayerReady, setAudioPlayerReady] = useState(true);
  const audioRef = useRef(null);

  const startAudio = () => {
    // alert('startAudio');
    const audio = audioRef.current;
    audio.volume = 1;
    audio.play();
    // audio.pause();
    // audio.currentTime = 0;
  };

  useEffect(() => {
    apiSocket.on("audio:add", (data) => {
      console.log("received voice", data);
      setAudios((audios) => [...audios, data]);
    });
    document.body.addEventListener("click", startAudio, true);

    return () => {
      document.body.removeEventListener("click", startAudio);
      apiSocket.removeListener("audio:add");
      return null;
    };
  }, []);

  const playAudio = (data) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/media/${data.uuid}.mp3`;
      const audio = audioRef.current;
      audio.src = url;
      audio.muted = false;
      audio.volume = 1;
      audio.play();
      audio.addEventListener("ended", () => {
        setAudioPlayerReady(true);
      });
    } catch (err) {
      playAudio(data);
    }
  };

  useEffect(() => {
    console.log("AUDIOPLAYER READY", audioPlayerReady);
  }, [audioPlayerReady]);

  useEffect(() => {
    if (audios.length > 0 && audioPlayerReady) {
      const audio = audios[0];
      setAudios((audios) => audios.slice(1));
      setAudioPlayerReady(false);
      if (isVoiceActive) playAudio(audio);
      console.log("audio duration", audio.duration);
      // setTimeout(() => setAudioPlayerReady(true), audio.duration * 1000 + 100);
    }
  }, [audios, audioPlayerReady]);

  return (
    <audio
      id={"audio-player"}
      playsInline={true}
      ref={audioRef}
      muted={true}
      volume={1}
    />
  );
}

export default AudioPlayer;
