import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";
import apiSocket from "../../services/socket";
import accountIcon from "../../assets/accountIcon.svg";
import { useEffect, useState } from "react";
import { getProfile } from "../../services/api";
import { useAuth } from "../../contexts/AuthContext";

export default function Home() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const { jwt, setJwt } = useAuth();

  const joinMeeting = (code) => {
    navigate(`/meetings?code=${code}`);
  };

  const fetchUser = async () => {
    if (!jwt) {
      navigate("/login");
      return;
    }
    const user = await getProfile(jwt);
    setUser(user);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleJoinMeeting = async (e) => {
    e.preventDefault();
    if (!e.target.code.value) return;
    joinMeeting(e.target.code.value);
  };

  const handleCreateMeeting = async (e) => {
    console.log("create meeting");
    e.preventDefault();
    apiSocket.emit("sessions:create");
    apiSocket.on("sessions:create", ({ id, code }) => {
      joinMeeting(code);
    });
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleJoinMeeting}>
        <input type={"text"} placeholder={"Code meeting"} name={"code"} />
        <input type={"submit"} value={"Rejoindre"} />
      </form>
      <div className={styles.separator} />
      <form onSubmit={handleCreateMeeting}>
        <input type={"submit"} value={"Créer un nouveau meeting"} />
      </form>
      {!!user && (
        <div
          className={styles.accountContainer}
          onClick={() => navigate("/account")}
        >
          <img src={accountIcon} className={styles.accountIcon} />
          <span className={styles.accountText}>
            {user.firstname} {user.lastname}
          </span>
        </div>
      )}
    </div>
  );
}
