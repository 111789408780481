import Select from "react-select";
import { useEffect, useState } from "react";
import styles from "./InputSelect.module.scss";
import { isMobile } from "react-device-detect";

export default ({ onChange }) => {
  const languages = [
    { value: "fr", label: "Français" },
    { value: "en", label: "English" },
    { value: "de", label: "Deutsch" },
    { value: "es", label: "Español" },
    { value: "it", label: "Italiano" },
    { value: "pt", label: "Portugues" },
    /// Add more languages here
    { value: "ar", label: "Arabic" },
    { value: "nl", label: "Dutch" },
    { value: "pl", label: "Polish" },
    { value: "sk", label: "Slovakian" },
    { value: "ru", label: "Russian" },
    // { value: "ja", label: "Japanese" },
    // { value: "cmn", label: "Mandarin" },
  ];

  return (
    <Select
      className={styles.select}
      onChange={({ value }) => {
        onChange(value);
      }}
      options={languages}
      placeholder={"Language"}
    />
  );
};
